<div class="auth-page">
  <div class="auth-page__content-block">
    <div class="auth-page__content-wrapper">
      <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="form__input">
          <input matInput placeholder="Login" type="text" formControlName="username">
        </mat-form-field>

        <mat-form-field class="form__input">
          <input matInput placeholder="Password" type="password" formControlName="password">
        </mat-form-field>

        <div class="form-actions">
          <button class="form-actions__login" mat-raised-button color="primary" type="submit">Войти</button>
          <button class="form-actions__forget" mat-button routerLink="/forgot">Вспомнить пароль</button>
        </div>
      </form>
    </div>
  </div>
  <div class="auth-page__logo">
    <div class="auth-page__logo-wrapper">
    </div>
  </div>
</div>
