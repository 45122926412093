import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Report } from "@app/models";



@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private _url = `${environment.api}/reports`;

  constructor(
    private http: HttpClient,
  ) { }

  main(): Observable<Report> {
    return this.http.get<Report>(`${this._url}/main`);
  }
}
