import { AuthenticationService } from '@app/services';
import { routes } from '../../../consts';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {PopupInfoComponent} from '@app/core/popup-info/popup-info.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';


@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent implements OnInit {
  public routers: typeof routes = routes;
  public loginForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private _bottomSheet: MatBottomSheet
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe(
        data => {
          if (this.authService.isLoggedin()) {
            this.router.navigate([routes.DASHBOARD]);
          }
        },
        (error: HttpErrorResponse) => {
          this._bottomSheet.open(PopupInfoComponent, {data: {messages: [error]}});
        }
      );
    }
  }
}
