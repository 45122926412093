import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Block} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class BlockService {
  private _url = `${environment.api}/blocks`;

  constructor(
    private http: HttpClient,
  ) { }

  getAll(): Observable<Block[]> {
    return this.http.get<Block[]>(this._url);
  }

  get(blockId: number): Observable<Block> {
    return this.http.get<Block>(`${this._url}/${blockId}`);
  }

  update(blockId: number, block: Block): Observable<boolean> {
    return this.http.patch<boolean>(`${this._url}/${blockId}`, block);
  }

}
