import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private _url = `${environment.api}/pages`;

  constructor(
    private http: HttpClient,
  ) { }

  getAll(): Observable<Page[]> {
    return this.http.get<Page[]>(this._url);
  }

  get(pageId: number): Observable<Page> {
    return this.http.get<Page>(`${this._url}/${pageId}`);
  }

  update(pageId: number, page: Page): Observable<boolean> {
    return this.http.patch<boolean>(`${this._url}/${pageId}`, page);
  }

}
