import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Item, ItemFile, UpdateFileResponse} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  private _url = `${environment.api}/items`;

  constructor(
    private http: HttpClient,
  ) { }

  getAll(): Observable<Item[]> {
    return this.http.get<Item[]>(this._url);
  }

  get(itemId: number): Observable<Item> {
    return this.http.get<Item>(`${this._url}/${itemId}`);
  }

  create(item: Item): Observable<Item> {
    return this.http.put<Item>(`${this._url}`, item);
  }

  update(itemId: number, item: Item): Observable<boolean> {
    return this.http.patch<boolean>(`${this._url}/${itemId}`, item);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this._url}/${id}`);
  }

  deleteFile(itemId: number, fileId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this._url}/${itemId}/files/${fileId}`);
  }

  updateFile(itemId: number, file: File, name: string): Observable<HttpEvent<UpdateFileResponse>> {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Encrypts', 'multipart/form-data');
    headers = headers.append('Accept', 'application/json');

    return this.http.post<UpdateFileResponse>(`${this._url}/${itemId}/files`, formData, {
      headers,
      reportProgress: true,
      observe: 'events'
    });
  }

  getFiles(itemId: number): Observable<ItemFile[]> {
    return this.http.get<ItemFile[]>(`${this._url}/${itemId}/files`);
  }

  addCover(itemId: number, file: File, name: string): Observable<HttpEvent<UpdateFileResponse>> {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Encrypts', 'multipart/form-data');
    headers = headers.append('Accept', 'application/json');

    return this.http.post<UpdateFileResponse>(`${this._url}/${itemId}/files`, formData, {
      headers,
      reportProgress: true,
      observe: 'events'
    });
  }

}
