import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError, finalize, tap } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import jwt_decode from 'jwt-decode';
import {UserShortModel, UserUpdateModel, ForgotModel, UserPayload} from '@app/models';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _api = environment.api;
  private _url = `${this._api}/personal`;
  private _key = 'currentUser';
  private _user: UserShortModel;
  private _isLoggedIn$: BehaviorSubject<boolean>;
  private _userName$: BehaviorSubject<string>;
  private _payload: UserPayload;
  private _userPayload$: BehaviorSubject<UserPayload>;

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {
    // Инициализируем пользователя
    this._user = this.getUser();
    this._userPayload$ = new BehaviorSubject(null);
    if (this._user && this._user.token) {
      this.setToket(this._user.token);
    }
    // Создаём Observer для состояния авторизации пользователя
    this._isLoggedIn$ = new BehaviorSubject(this._isLoggedIn());
    // Создаём Observer для имени пользователя
    this._userName$ = new BehaviorSubject(this.userName);
  }


  private _isLoggedIn(): boolean {
    return this._user && !!this._user.token;
  }

  private _setLoginStatus(state: boolean): void {
    this._isLoggedIn$.next(state);
  }

  get payload(): UserPayload {
    return this._payload;
  }


  getPayload(): Observable<UserPayload> {
    return this._userPayload$.asObservable();
  }

  get userName(): string {
    if (!!this._user) {
      return this._user.firstName + ' ' + this._user.lastName;
    } else {
      return null;
    }
  }

  get userId(): number {
    return +this.getUser().id;
  }

  getUser(): UserShortModel {
    return JSON.parse(localStorage.getItem(this._key)) as UserShortModel;
  }

  isLoggedin(): boolean {
    return this._isLoggedIn();
  }

  getLoginStatus(): Observable<boolean> {
    return this._isLoggedIn$.asObservable();
  }

  getUserName(): Observable<string> {
    return this._userName$.asObservable();
  }

  updatePersonalInfo(user: UserShortModel): void {
    localStorage.setItem(this._key, JSON.stringify(user));
    this._user = user;
    this._userName$.next(`${user.firstName} ${user.lastName}`);
    this.setToket(user.token);
  }

  setToket(token: string): void {
    this._payload = jwt_decode(token);
    this._userPayload$.next(this._payload);
  }

  flushProfileInfo(): void {
    localStorage.removeItem(this._key);
    this._user = null;
    this._payload = null;
    this._userName$.next(null);
  }

  flushUserInfo(): void {
    localStorage.removeItem(this._key);
    this._user = null;
    this._userName$.next(null);
  }

  login(request: { username: string, password: string }): Observable<UserShortModel> {
    const body = new FormData();
    body.append('username', request.username);
    body.append('password', request.password);
    return this.http.post<UserShortModel>(`${this._url}/auth`, body).pipe(
      tap(user => {
        this.updatePersonalInfo(user);
        this._setLoginStatus(this._isLoggedIn());
      }),
      catchError(error => {
        this._setLoginStatus(false);
        return throwError(error);
      })
    );
  }

  updateProfileInfo(profile): Observable<UserUpdateModel> {
    return this.http.post<UserUpdateModel>(`${this._url}/profiles`, profile).pipe(
      tap(user => {
        localStorage.setItem(this._key, JSON.stringify(user));
        this._setLoginStatus(this._isLoggedIn());
      }),
      catchError(error => {
        this._setLoginStatus(false);
        console.log(error);
        return throwError(error);
      })
    );
  }

  updateUserInfo(user): void {
    localStorage.setItem(this._key, JSON.stringify(user));
  }


  syncLogout(): Observable<any> {
    return this.http.post(`${this._url}/logout`, {}).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        return throwError(error);
      }),
      finalize(() => {
        this.logout();
      }));
  }

  logout(returnUrl?: string): void {
    if (this._isLoggedIn()) {
      this.flushUserInfo();
      this._setLoginStatus(false);
      this.router.navigate(['/login']);
    }
  }

  /*?????*/
  forgot(data): Observable<any> {
    return this.http.post<ForgotModel>(`${this._url}/forgot`, data).pipe(
      tap(user => {
        localStorage.setItem(this._key, JSON.stringify(user));
        this._setLoginStatus(this._isLoggedIn());
      }),
      catchError(error => {
        this._setLoginStatus(false);
        console.log(error);
        return throwError(error);
      })
    );
  }
















  /*getUser(): UserShortModel {
    return this._userShort.getValue();
  }

  getUserName(): Observable<string> {
    return this._userName$.asObservable();
  }



  updateToken(token: string): void {
    console.log('updatetoken');
    this.getUser().token = token;
    localStorage.setItem(this._key, JSON.stringify(this.getUser()));
  }

  flushUserInfo(): void {
    console.log('flush');
    localStorage.removeItem(this._key);
    this._userShort.next(null);
    this._userName$.next(null);
  }*/






}
