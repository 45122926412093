import { Component, EventEmitter, Input, Output,OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { UserShortModel } from '@app/models';
import { routes } from '../../../../consts';
import {AuthenticationService, ProfileService} from '@app/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
  @Input() isMenuOpened: boolean;
  @Output() isShowSidebar = new EventEmitter<boolean>();
  // public user$: string/*Observable<UserShortModel>*/;
  public routers: typeof routes = routes;
  public userName$: Observable<string>;

  constructor(
    private userService: AuthenticationService,
    private profileService: ProfileService,
    private router: Router
  ) {
    this.userName$ = this.profileService.userName$;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.profileService.get().subscribe();
  }


  public openMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
    this.isShowSidebar.emit(this.isMenuOpened);
  }

  public signOut(): void {
    this.userService.syncLogout();
    this.router.navigate([this.routers.LOGIN]);
  }
}
