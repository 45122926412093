import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import {AuthPageComponent} from './auth-page/auth-page.component';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthGuard } from './guards';
import { AuthenticationService } from '@app/services';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {SharedModule} from '@app/shared/shared.module';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {PopupInfoComponent} from "@app/core/popup-info/popup-info.component";
import {ErrorComponent} from "@app/_core";

@NgModule({
    declarations: [
        AuthPageComponent
    ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        MatTabsModule,
        MatButtonModule,
        FormsModule,
        MatCardModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatFormFieldModule,
        SharedModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatSortModule,
        MatBottomSheetModule,
        MatOptionModule,
        MatDialogModule,
        MatProgressBarModule,
        FormsModule
    ],
    providers: [
        AuthenticationService,
        AuthGuard
    ]
})
export class AuthModule { }
