export enum routes {
  DASHBOARD = '/dashboard',
  ORDERS = '/orders',
  CATEGORIES = '/categories',
  ITEMS = '/items',
  REFS = '/refs',
  BRANDS = '/brands',
  MATERIALS = '/materials',
  COLORS = '/colors',
  SIZES = '/sizes',
  PAGES = '/pages',
  BLOCKS = '/blocks',
  USERS = '/users',
  TYPOGRAPHY = '/typography',
  TABLES = '/tables',
  UI_ELEMENTS_ICONS = '/ui/icons',
  UI_ELEMENTS_CHARTS = '/ui/charts',
  UI_ELEMENTS_MAP = '/ui/map',
  LOGIN = '/login'
}
