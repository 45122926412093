<div class="not-found-page">
  <div class="not-found-page__content">
    <div class="not-found-page__title">
      <h3 class="not-found-page__title-text">FoxBazaar</h3>
    </div>

    <mat-card class="not-found-page__card">
      <mat-card-content class="not-found-page__content">
        <h1 class="not-found-page__card-title">404</h1>

        <p class="not-found-page__card-sub-title">Такой страницы не существует</p>
        <button class="not-found-page__card-button" mat-raised-button color="primary" [routerLink]="routes.DASHBOARD">На главную</button>

      </mat-card-content>
    </mat-card>
  </div>
</div>
