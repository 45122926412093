import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { AuthenticationService } from '@app/services';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import { ErrorComponent } from '@app/core/error/error.component';

@Injectable()
export class HTTPListener implements HttpInterceptor {

    constructor(
        private authService: AuthenticationService,
        private bottomSheet: MatBottomSheet,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentProfile = this.authService.getUser();
        if (currentProfile && currentProfile.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentProfile.token}`
                }
            });
        }
        return next.handle(request).pipe(
            catchError(error => {
                if (error.status === 401 && this.authService.isLoggedin()) {
                    this.authService.logout();
                }
                if (error.status === 422) {
                    this.bottomSheet.open(ErrorComponent, { data: { messages: [{ text: error.error.message }] } });
                }
                if (error.status === 418) {
                    this.authService.logout();
                }
                if (error.status === 300) {
                    return throwError(error);
                }
                if (error.error && error.error.messages) {
                    this.bottomSheet.open(ErrorComponent, { data: error.error });
                }

                return throwError(error);
            }),
        );
    }
}
