import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardPageComponent } from './pages/dashboard/containers';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import {AuthGuard} from './pages/auth/guards';

const routes: Routes = [
  {
    path: 'dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: DashboardPageComponent
  },
  {
    path: 'orders',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'items',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/items/items.module').then(m => m.ItemsModule)
  },
  {
    path: 'categories',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesModule)
  },
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'blocks',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/blocks/blocks.module').then(m => m.BlocksModule)
  },
  {
    path: 'refs/materials',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/features/features.module').then(m => m.FeaturesModule)
  },
  {
    path: 'refs/colors',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/features/features.module').then(m => m.FeaturesModule)
  },
  {
    path: 'refs/brands',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/features/features.module').then(m => m.FeaturesModule)
  },
  {
    path: 'refs/sizes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/features/features.module').then(m => m.FeaturesModule)
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/profiles/profiles.module').then(m => m.ProfilesModule)
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/forgot/forgot.module').then(m => m.ForgotModule)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
