<mat-list>
  <mat-list-item routerLink="{{routes.DASHBOARD}}" routerLinkActive="active">
    <mat-icon>home</mat-icon> Главная
  </mat-list-item>
  <mat-list-item routerLink="{{routes.CATEGORIES}}" routerLinkActive="active">
    <mat-icon>category</mat-icon> Категории
  </mat-list-item>
  <mat-list-item routerLink="{{routes.ITEMS}}" routerLinkActive="active">
    <mat-icon>inventory</mat-icon> Товары
  </mat-list-item>
  <mat-list-item>
    <mat-icon>menu_book</mat-icon> Справочники
  </mat-list-item>
  <div>
    <mat-list-item class="ui-element" routerLink="{{routes.REFS}}/{{routes.BRANDS}}" routerLinkActive="active">
      <div class="circle"></div>Производители
    </mat-list-item>
    <mat-list-item class="ui-element" routerLink="{{routes.REFS}}/{{routes.MATERIALS}}" routerLinkActive="active">
      <div class="circle"></div>Материалы
    </mat-list-item>
    <mat-list-item class="ui-element" routerLink="{{routes.REFS}}/{{routes.COLORS}}" routerLinkActive="active">
      <div class="circle"></div>Цвета
    </mat-list-item>
    <mat-list-item class="ui-element" routerLink="{{routes.REFS}}/{{routes.SIZES}}" routerLinkActive="active">
      <div class="circle"></div>Размеры
    </mat-list-item>
  </div>
  <mat-list-item routerLink="{{routes.ORDERS}}" routerLinkActive="active">
    <mat-icon>shopping_cart</mat-icon> Заказы
  </mat-list-item>
  <mat-list-item routerLink="{{routes.PAGES}}" routerLinkActive="active">
    <mat-icon>home</mat-icon> Страницы
  </mat-list-item>
  <div>
    <mat-list-item class="ui-element" routerLink="{{routes.BLOCKS}}" routerLinkActive="active">
      <div class="circle"></div>Блоки
    </mat-list-item>
  </div>
  <mat-list-item routerLink="{{routes.USERS}}" routerLinkActive="active">
    <mat-icon>people</mat-icon> Пользователи
  </mat-list-item>
