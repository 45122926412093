import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-popup-info',
  templateUrl: './popup-info.component.html',
  styleUrls: ['./popup-info.component.scss']
})
export class PopupInfoComponent {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<PopupInfoComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
  ) { }

}
