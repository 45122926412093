import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';

import { HeaderModule } from './header/header.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { SettingsMenuComponent } from '@app/shared/ui-elements';
import { DateMenuComponent } from '@app/shared/ui-elements';
import { LayoutComponent } from './layout/layout.component';
import {MomentPipe, PhonePipe} from '@app/pipe';
import {MatDialogClose, MatDialogModule} from '@angular/material/dialog';
import {ConfirmComponent} from '@app/shared/confirm/confirm.component';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';


@NgModule({
  declarations: [
    SidebarComponent,
    FooterComponent,
    SettingsMenuComponent,
    DateMenuComponent,
    LayoutComponent,
    PhonePipe,
    MomentPipe,
    ConfirmComponent
  ],
    imports: [
        HeaderModule,
        MatListModule,
        MatIconModule,
        RouterModule,
        MatButtonModule,
        CommonModule,
        MatMenuModule,
        MatSelectModule,
        FormsModule,
        MatSidenavModule,
        MatDialogModule,
        MatBottomSheetModule,
        MatCardModule,
        MatInputModule,
        MatRadioModule
    ],
  exports: [
    HeaderModule,
    SidebarComponent,
    FooterComponent,
    SettingsMenuComponent,
    DateMenuComponent,
    LayoutComponent,
    PhonePipe,
    MomentPipe,
    ConfirmComponent
  ]
})
export class SharedModule { }
