import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserFullModel} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _url = `${environment.api}/users`;

  constructor(
    private http: HttpClient,
  ) { }

  getAll(): Observable<UserFullModel[]> {
    return this.http.get<UserFullModel[]>(this._url);
  }

  get(userId: number): Observable<UserFullModel> {
    return this.http.get<UserFullModel>(`${this._url}/${userId}`);
  }

  create(user: UserFullModel): Observable<UserFullModel> {
    return this.http.post<UserFullModel>(`${this._url}`, user);
  }

  update(userId: number, user: UserFullModel): Observable<boolean> {
    return this.http.patch<boolean>(`${this._url}/${userId}`, user);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this._url}/${id}`);
  }
}

