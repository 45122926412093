import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/services';
import {routes} from "@app/consts";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private readonly authService: AuthenticationService,
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedin()) {
      console.log('logged');
      return true;
    } else {
      console.log('not logged');
      this.router.navigate([routes.LOGIN]);
      return false;
    }
  }
}

