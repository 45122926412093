<app-layout>
  <mat-toolbar class="page-header" role="heading">
    <h1>Главная</h1>
  </mat-toolbar>
  <div class="project-stat">
    <mat-card class="project-stat__item">
      <mat-card-title class="project-stat__title">
        <h5 class="project-stat__title-text">Новые заказы</h5>
      </mat-card-title>
      <mat-card-content class="project-stat-content">
        <div class="project-stat-content__total-info-wrapper">
          <div class="project-stat-content__total-info">
            <p class="project-stat-content__total-info-users">{{(mainData$ | async)?.new}}</p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="project-stat__item">
      <mat-card-title class="project-stat__title">
        <h5 class="project-stat__title-text">Ждут отправки</h5>
      </mat-card-title>
      <mat-card-content class="project-stat-content">
        <div class="project-stat-content__total-info-wrapper">
          <div class="project-stat-content__total-info">
            <p class="project-stat-content__total-info-users">{{(mainData$ | async)?.paid}}</p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="project-stat__item">
      <mat-card-title class="project-stat__title">
        <h5 class="project-stat__title-text">Отправлено</h5>
      </mat-card-title>
      <mat-card-content class="project-stat-content">
        <div class="project-stat-content__total-info-wrapper">
          <div class="project-stat-content__total-info">
            <p class="project-stat-content__total-info-users">{{(mainData$ | async)?.sent}}</p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</app-layout>
