import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {PasswordUpdateModel, UserFullModel, UserUpdateModel} from '@app/models';
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private _url = `${environment.api}/profiles`;
  private _userName = new BehaviorSubject<string>('');
  public userName$ = this._userName.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  get(): Observable<UserUpdateModel> {
    return this.http.get<UserUpdateModel>(`${this._url}`).pipe(
      tap(response => this._userName.next(`${response.firstName} ${response.lastName}`))
    );
  }

  update(profile: UserUpdateModel): Observable<boolean> {
    return this.http.patch<boolean>(`${this._url}`, profile).pipe(
      tap(response => this._userName.next(`${profile.firstName} ${profile.lastName}`))
    );
  }

  password(password: PasswordUpdateModel): Observable<boolean> {
    return this.http.patch<boolean>(`${this._url}/password`, password);
  }
}

