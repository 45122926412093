import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Feature} from '@app/models';


@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  private _url = `${environment.api}/features`;

  constructor(
    private http: HttpClient,
  ) { }

  getList(code: string): Observable<Feature[]> {
    return this.http.get<Feature[]>(`${this._url}/${code}`);
  }

  get(code: string, id: number): Observable<Feature> {
    return this.http.get<Feature>(`${this._url}/${code}/${id}`);
  }
  create(code: string, feature: Feature): Observable<Feature> {
    return this.http.post<Feature>(`${this._url}/${code}`, feature);
  }
  update(code: string, id: number, feature: Feature): Observable<Feature> {
    return this.http.patch<Feature>(`${this._url}/${code}/${id}`, feature);
  }
  delete(code: string, id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this._url}/${code}/${id}`);
  }

}
