import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Category, CategoryFile, UpdateCategoryFileResponse} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private _url = `${environment.api}/categories`;

  constructor(
    private http: HttpClient,
  ) { }

  getAll(): Observable<Category[]> {
    return this.http.get<Category[]>(this._url);
  }

  get(categoryId: number): Observable<Category> {
    return this.http.get<Category>(`${this._url}/${categoryId}`);
  }

  create(category: Category): Observable<Category> {
    return this.http.put<Category>(`${this._url}`, category);
  }

  update(categoryId: number, category: Category): Observable<boolean> {
    return this.http.patch<boolean>(`${this._url}/${categoryId}`, category);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this._url}/${id}`);
  }

  deleteFile(categoryId: number, type): Observable<boolean> {
    return this.http.delete<boolean>(`${this._url}/${categoryId}/files/${type}`);
  }

  updateFile(categoryId: number, file: File): Observable<HttpEvent<UpdateCategoryFileResponse>> {

    const formData = new FormData();
    formData.append('file', file);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Encrypts', 'multipart/form-data');
    headers = headers.append('Accept', 'application/json');

    return this.http.post<UpdateCategoryFileResponse>(`${this._url}/${categoryId}/files`, formData, {
      headers,
      reportProgress: true,
      observe: 'events'
    });
  }

}
