import {Component, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import {ReportService} from '@app/services';
import {Report} from '@app/models';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {
  public mainData$: Observable<Report>;

  constructor(
    private serviceReport: ReportService,
  ) {
  }

  ngOnInit() {
    this.mainData$ = this.serviceReport.main();
  }
}
