<mat-toolbar class="header">

  <div class="header__title">
    <button class="header__title-button" mat-mini-fab (click)="openMenu()">
      <mat-icon *ngIf="!isMenuOpened" class="header__title-button-icon">menu</mat-icon>
      <mat-icon *ngIf="isMenuOpened" class="header__title-button-icon">arrow_back</mat-icon>
    </button>
    <h6 class="header__title-text">FoxBazaar: Панель администратора</h6>
  </div>
  <div class="header-toolbar">
    <button class="user-button" mat-mini-fab [matMenuTriggerFor]="userMenu">
      <mat-icon class="user-button__icon">person</mat-icon>
    </button>
    <mat-menu #userMenu="matMenu" xPosition="before">
      <div class="user-menu-title">
        <h4 class="user-menu-title__name">{{userName$ | async}}</h4>
      </div>
      <button mat-menu-item class="user-menu__item-title" routerLink="/profile">
        <mat-icon class="user-menu-icon">person</mat-icon>Редактировать профиль
      </button>
      <button mat-menu-item class="user-menu__item-title" routerLink="/profile/password">
        <mat-icon class="user-menu-icon">pin</mat-icon>Редактировать пароль
      </button>
      <div class="sign-button-wrapper">
        <button class="sign-button" mat-flat-button (click)="signOut()">Выход</button>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
