import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ReferenceDeliveryMethods, ReferencePaymentMethods, ReferenceOrderStatuses } from "@app/models";



@Injectable({
  providedIn: 'root'
})
export class ReferenceService {
  private _url = `${environment.api}/references`;

  constructor(
    private http: HttpClient,
  ) { }

  getDeliveryMethods(): Observable<ReferenceDeliveryMethods> {
    return this.http.get<ReferenceDeliveryMethods>(`${this._url}/delivery/methods`);
  }
  getPaymentMethods(): Observable<ReferencePaymentMethods> {
    return this.http.get<ReferencePaymentMethods>(`${this._url}/payment/methods`);
  }

  getOrderStatuses(): Observable<Array<ReferenceOrderStatuses>> {
    return this.http.get<Array<ReferenceOrderStatuses>>(`${this._url}/order/statuses`);
  }
}
